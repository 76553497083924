// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/frontend and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import Rails from '@rails/ujs';
import 'lazysizes';

import '../styles/application.scss';

// Components
import '../../components/base/button/component';
import '../../components/base/slider/component';
import '../../components/base/map/component';
import '../../components/base/dropdown_component/dropdown_component';
import '../../components/base/modal_component/modal_component';
import '../../components/base/tabs_with_anchor/component';
import '../../components/base/reviews_component/reviews_component';
import '../../components/base/reviews_carousel_component/reviews_carousel_component';
import '../../components/base/read_more_text_component/read_more_text_component';
import '../../components/base/carousel_component/carousel_component';
import '../../components/base/pro_accordion_card/component';
import '../../components/base/pro_counter_proposal_card/component';
import '../../components/base/youtube_video/component';

// Pro profiles
import '../../components/pro_profiles/description_component/description_component';
import '../../components/pro_profiles/pro_presentation_component/pro_presentation_component';
import '../../components/pro_profiles/welcome_notice_component/welcome_notice_component';

// Layouts
import '../../components/layouts/breadcrumb_component/breadcrumb_component';
import '../../components/layouts/header/component';
import '../../components/layouts/footer/component';

// Utility
import '../javascripts/utils/obfuscated_link';
import '../javascripts/utils/show_more';
import '../javascripts/utils/change_if_visible';
import '../javascripts/utils/trigger_click';
import '../javascripts/utils/react_header_helper';

// Form
import '../../components/form/select_component/select_component';
import '../../components/form/input/phone/component';
import '../../components/form/button_group/component';
import '../../components/form/date_picker/component';
import '../../components/form/autocomplete_address/component';
import '../../components/form/input/component';
import '../../components/form/input_validated_password/component';
import '../../components/landing_pages/pro/subscription_form_component';

require.context('../images', true);

// Start Rails

Rails.start();
