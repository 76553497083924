window.addEventListener('DOMContentLoaded', () => {
  const alsoANannyCheck = document.getElementById('pro_subscription_also_a_nanny');
  if (alsoANannyCheck) {
    alsoANannyCheck.addEventListener('change', function onChange() {
      const selector = document.getElementById('pro_subscription_childcare_availability');
      if (this.checked) {
        selector.parentElement.classList.remove('form-select--hidden');
      } else {
        selector.parentElement.classList.add('form-select--hidden');
        selector.value = null;
      }
    });
  }

  const leadCompanyStatus = document.getElementById('pro_subscription_company_status');
  const universe = document.getElementById('universe');

  // Fields to display when the pro does not have company
  const canWorkInFranceInput = document.getElementById('pro_subscription_can_work_in_france');

  // Fields to display when the pro do not have company and can work in France because of a permit
  const permitTypeInput = document.getElementById('pro_subscription_residence_permit_type');
  const permitExpirationDateInput = document.getElementById('pro_subscription_residence_permit_expiration_date');

  // Fields to display when the pro have a company
  const sirenInput = document.getElementById('pro_subscription_siren');
  const novaStatusInput = document.getElementById('pro_subscription_nova_status');

  function toggleNoCompanyFields(shouldDisplay = true) {
    if (shouldDisplay) {
      canWorkInFranceInput.parentElement.classList.remove('form-select--hidden');
    } else {
      canWorkInFranceInput.parentElement.classList.add('form-select--hidden');
      canWorkInFranceInput.value = null;
    }
  }

  function toggleCompanyFields(shouldDisplay = true) {
    if (shouldDisplay) {
      sirenInput.parentElement.classList.remove('form-input--hidden');
      novaStatusInput.parentElement.classList.remove('form-select--hidden');
    } else {
      sirenInput.parentElement.classList.add('form-input--hidden');
      novaStatusInput.parentElement.classList.add('form-select--hidden');
      sirenInput.value = null;
      novaStatusInput.value = null;
    }
  }

  function toggleResidentPermitFields(shouldDisplay = true) {
    if (shouldDisplay) {
      permitTypeInput.parentElement.classList.remove('form-select--hidden');
      permitExpirationDateInput.parentElement.classList.remove('form-input--hidden');
    } else {
      permitTypeInput.parentElement.classList.add('form-select--hidden');
      permitExpirationDateInput.parentElement.classList.add('form-input--hidden');
      permitTypeInput.value = null;
      permitExpirationDateInput.value = null;
    }
  }

  if (universe && ['cleaning', 'childcare'].includes(universe.value) && leadCompanyStatus) {
    leadCompanyStatus.addEventListener('change', function onChange() {
      if (this.value === '') {
        toggleNoCompanyFields(false);
        toggleCompanyFields(false);
        toggleResidentPermitFields(false);
        return;
      }
      if (this.value === 'no_company') {
        toggleNoCompanyFields();
        toggleCompanyFields(false);
      } else {
        toggleNoCompanyFields(false);
        toggleResidentPermitFields(false);
        toggleCompanyFields();
      }
    });

    canWorkInFranceInput.addEventListener('change', function onChange() {
      toggleResidentPermitFields(this.value === 'yes_resident');
    });
  }
});
